import itemSchema from './schemas/device.schema';
import ModuleBase from "@/store/modules/ModuleBase";
import CONSTANTS from '@/config/constants'

const resourceName = 'devices'
const base = new ModuleBase(itemSchema, resourceName);
export default {
  namespaced: base.namespaced,
  state: {
    ...base.state,
    listStatus: [
      {
        value: '',
        text: 'All status'
      },
      {
        value: CONSTANTS.DEVICE_STATUS.OPEN,
        text: 'Active'
      },
      {
        value: CONSTANTS.DEVICE_STATUS.CLOSE,
        text: 'Close'
      }
    ]
  },
  getters: {
    ...base.getters,
    getStatusText: state => status => {
      let data = state.listStatus.filter(s => s.value === status)
      if (data.length > 0) return data[0]['text']
      return ''
    },
    listStatus: state => state.listStatus
  },
  mutations: {
    ...base.mutations,
  },
  actions: {
    ...base.actions
  }
}
