module.exports = {
  NOTICE_TYPE: {
    INFO: 1,
    SUCCESS: 2,
    WARNING: 3,
    ERROR: 4
  },
  ROLES: {
    SUPER_ADMINISTRATOR: 1,
    NORMAL_USER: 2,
    EDITOR: 3,
    VISITOR: 4,
    COLLABORATOR: 5
  },
  DEVICE_STATUS: {
    CLOSE: 0,
    OPEN: 1
  }
}
